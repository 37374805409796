import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  Grid,
  IconButton,
  styled,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useCreateEditPaylaterTier, useGetOnePaylaterTier } from './hooks';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

FormCreateTier.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formType: PropTypes.oneOf(['Create', 'Edit']),
  id: PropTypes.string,
};

export default function FormCreateTier({ open, setOpen, formType, id }) {
  const { register, errors, handleClose, handleCreateEdit, loadingCreate, reset, loadingEdit } =
    useCreateEditPaylaterTier({
      closeForm: setOpen,
      id,
    });

  const { data } = useGetOnePaylaterTier(id);

  React.useEffect(() => {
    reset(data?.data);
  }, [data, reset]);

  return (
    <>
      <BootstrapDialog
        onClose={() => handleClose(setOpen)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {formType} Paylater Tier
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(setOpen)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Tier Name</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                {...register('name', {
                  required: 'Tier Name is required',
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Min Loan</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('min_loan', {
                  required: 'Min Loan is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.min_loan)}
                helperText={errors.min_loan?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Max Loan</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('max_loan', {
                  required: 'Max Loan is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.max_loan)}
                helperText={errors.max_loan?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Tier Level</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('tier_level', {
                  required: 'Tier Level is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.tier_level)}
                helperText={errors.tier_level?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(setOpen)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => handleCreateEdit(formType)}
            disabled={loadingCreate || loadingEdit}
          >
            {loadingCreate || loadingEdit ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
