import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  alertTitle: PropTypes.string,
  handleDelete: PropTypes.func,
  loadingDelete: PropTypes.bool,
};

export default function DeleteDialog({ open, setOpen, alertTitle, handleDelete, loadingDelete }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-delete"
      aria-describedby="alert-delete-description"
    >
      <DialogTitle id="alert-delete">{alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-delete-description">
          Are you sure? You wont be able to revert this!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete} variant="contained" disabled={loadingDelete}>
          {loadingDelete ? 'Loading...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
