import { Autocomplete, Avatar, Box, Button, Card, Container, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';

import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import DatePicker from '../../../../components/date-picker';
import Datatable from '../../../../components/datatable';
import { useGetPayoutReqCompany } from './hooks';
import { formatIDR } from '../../../../utils/formatNumber';

export default function PayoutSettingRequestPage() {
  const cellStyle = { fontSize: 14 };

  const navigate = useNavigate();

  // state
  const [date, setDate] = useState(dayjs(new Date()));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading } = useGetPayoutReqCompany({
    page: page + 1,
    page_size: rowsPerPage,
  });

  const columns = [
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          No
        </Typography>
      ),
      width: '4rem',
      cell: (_, i) => <Typography sx={{ ...cellStyle }}>{i + 1}.</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Date
        </Typography>
      ),
      width: '12rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{moment(row.created_at).format('LLLL')}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Trx Number
        </Typography>
      ),
      width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.transaction_id}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Company
        </Typography>
      ),
      sortable: true,
      width: '16rem',
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar src={row.logo} alt="photoURL" sx={{ marginRight: 2 }} />
          <Box>
            <Typography fontWeight="bold">{row.company.name}</Typography>
            <Typography fontSize={14} color="#9eabc0">
              {row.company.email}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Method
        </Typography>
      ),
      sortable: true,
      width: '10rem',
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.payment_method}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Amount
        </Typography>
      ),
      sortable: true,
      width: '10rem',
      cell: (row) => <Typography sx={{ ...cellStyle }}>{formatIDR(row.amount)} IDR</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Charge
        </Typography>
      ),
      sortable: true,
      width: '10rem',
      cell: (row) => (
        <Typography sx={{ ...cellStyle }} color="error">
          {formatIDR(row.fee)} IDR
        </Typography>
      ),
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Net Amount
        </Typography>
      ),
      width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{formatIDR(row.total)} IDR</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Status
        </Typography>
      ),
      sortable: true,
      cell: (row) => {
        let buttonColor = 'primary';
        if (row.status === 'approved') {
          buttonColor = 'success';
        } else if (row.status === 'pending') {
          buttonColor = 'warning';
        }

        return (
          <Label
            variant="filled"
            color={buttonColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              color: '#fff',
            }}
          >
            {row.status}
          </Label>
        );
      },
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Action
        </Typography>
      ),
      sortable: true,
      cell: (row) => (
        <Label
          variant="filled"
          color={'primary'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/payouts/setting/request/list/${row.id}`);
          }}
        >
          <Iconify style={{ color: '#fff' }} icon={'eva:eye-fill'} /> &nbsp; View
        </Label>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Payout Request | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Payout Request
          </Typography>
        </Stack>

        <Card>
          <Box paddingY={2} paddingX={3} display="flex" alignItems="start" justifyContent="space-between">
            <TextField id="outlined-basic" placeholder="Email/Trx" variant="outlined" />

            <Autocomplete
              id="combo-box-demo"
              options={['All Payment', 'Pending Payment', 'Complete Payment', 'Cancel Payment']}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} placeholder="Payment" />}
            />

            <DatePicker value={date} setValue={setDate} />

            <Button
              variant="contained"
              size="large"
              startIcon={<Iconify icon="eva:search-fill" sx={{ color: 'white', width: 20 }} />}
            >
              Search
            </Button>
          </Box>
          <Datatable
            columns={columns}
            data={data?.data?.data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            progressPending={isLoading}
            totalData={data?.data.total_data ?? 0}
          />
        </Card>
      </Container>
    </>
  );
}
