import DataTable from 'react-data-table-component';
import { TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import Loading from '../loading';

Datatable.propTypes = {
  progressPending: PropTypes.bool,
  setRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  columns: PropTypes.array,
  setPage: PropTypes.func,
  page: PropTypes.number,
  data: PropTypes.array,
  totalData: PropTypes.number,
};

export default function Datatable({
  columns,
  data,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  progressPending,
  totalData,
}) {
  const customStyles = {
    headCells: {
      style: {
        width: '100%',
        backgroundColor: '#f4f6f8',
        color: '#637381',
      },
    },
    cells: {
      style: {
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: '16px',
      },
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        progressPending={progressPending}
        progressComponent={<Loading />}
      />

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
