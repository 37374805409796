import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Grid, Typography, Alert, CardMedia, CardContent, Container, Stack } from '@mui/material';
import { formatIDR } from '../../utils/formatNumber';

import { useDashboard } from './hooks';

export default function RecipeReviewCard() {
  const { dataDashboard, totalBalance } = useDashboard();

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
          Dashboard | B2C
        </Typography>
        <Alert severity="success">
          <b>Total Balance: Rp. {formatIDR(totalBalance)}</b>
        </Alert>
      </Stack>
      <Grid container spacing={3}>
        {dataDashboard.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 350 }}>
              <CardHeader title={value?.name} subheader={value?.email} sx={{ marginBottom: 2 }} />
              <CardMedia
                component="img"
                height="194"
                image={value?.logo ? value?.logo : 'https://cdn.invoiceplus.id/dev/no-image.jpeg'}
                alt="Paella dish"
              />
              <CardContent>
                <Typography variant="caption" display="block" gutterBottom>
                  Bill Paid Gateway Count: {value?.bill_paid_gateway_count}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Bill Paid Manual Count: {value?.bill_paid_manual_count}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Bill Pending Count: {value?.bill_pending_count}
                </Typography>

                <hr />

                <Typography variant="caption" display="block" gutterBottom>
                  Invoice Paid Gateway Count: {value?.invoice_paid_gateway_count}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Invoice Paid Manual Count: {value?.invoice_paid_manual_count}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Invoice Pending Count: {value?.invoice_pending_count}
                </Typography>

                <hr />

                <Typography variant="caption" display="block" gutterBottom>
                  Total Bill Paid Gateway: {value?.total_bill_paid_gateway}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Total Bill Paid Manual: {value?.total_bill_paid_manual}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Total Bill Pending: Rp. {formatIDR(value?.total_bill_pending)}
                </Typography>

                <hr />

                <Typography variant="caption" display="block" gutterBottom>
                  Total Invoice Paid Gateway: {value?.total_invoice_paid_gateway}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Total Invoice Paid Manual: {value?.total_invoice_paid_manual}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  Total Invoice Pending: Rp. {formatIDR(value?.total_invoice_pending)}
                </Typography>

                <Alert severity="success">
                  <b>Balance: Rp. {formatIDR(value?.balance)}</b>
                </Alert>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
