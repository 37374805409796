import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';
import { paramsToQuery } from '../../utils/formatParamsToQuery';
import { saveAs } from '../../utils/saveAs';

// get payout request
export const useGetGatewayPayoutReq = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.GATEWAY_PAYOUT}/admin/req?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getMarketplacePayout', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// get detail payout request
export const useGetGatewayPayoutReqDetail = (id) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const res = await api.get(`${endpoint.GATEWAY_PAYOUT}/admin/${id}`, getAuthHeader);

      if (res.data.success) {
        return res.data.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getDetailMarketplace', id],
    queryFn: () => doGet(),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// post confirm req company
export const useConfirmRejectGatewayPayoutReq = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doAction = async (payload) => {
    try {
      const res = await api.post(
        `${endpoint.GATEWAY_PAYOUT}/admin/${payload.id}/confirm-reject`,
        {
          note: payload.note,
          status: payload.status,
          otp: payload.otp,
        },
        getAuthHeader
      );

      if (res.data.success) {
        toastAlert('Berhasil, mengubah status', 'success');

        payload.setShowOtp(false);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useMutation(doAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getDetailMarketplace']);
    },
  });
};

// get gateway payout log
export const useGetGatewayPayoutLog = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.GATEWAY_PAYOUT}/admin/log?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getMarketplacePayoutLog', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// download invoice
export const useDownloadInvoice = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const download = async (id) => {
    setIsloading(true);
    try {
      const response = await axios.get(`${endpoint.INVOICES}/download/${id}`, {
        responseType: 'arraybuffer', // Important for binary data
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      saveAs(response.data, `invoice-${id}.pdf`);
    } catch (err) {
      toastAlert(err.response.statusText, 'error');

      if (err.response.data.error_code === 'E-00050') {
        setTimeout(() => {
          navigate('/login');

          dispatch({
            type: 'DATA_USER_ADMIN_AUTH_EMPTY',
          });
        }, 500);
      }
    } finally {
      setIsloading(false);
    }

    return null;
  };

  return { download, isLoading };
};


// get payout manual add
export const useGetGatewayPayoutAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const INITIAL_STATE_ADD = {
    "agregator_email": "",
    "amount": 0,
    "bank_account": "",
    "bank_account_name": "",
    "bank_branch": "",
    "bank_code": "",
    "bank_name": "",
    "note": "",
    "released_at": "",
    "sender": "",
    "transfer_order_number": ""
  };

  const [dataFormPayoutManual, setDataFormPayoutManual] = useState(INITIAL_STATE_ADD);
  const [dataBank, setDataBank] = useState([]);
  const [dataUserB2b, setDataUserB2b] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    doGetBank();
    doGetUserB2b();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const doGetBank = async () => {
    try {
      const res = await api.get(`${endpoint.PAYOUT_BANK}`, getAuthHeader);
      if (res.data.success) {
        const dataPush = [];
        for (let i = 0; i < res?.data.data.length; i += 1) {
          dataPush.push({
            'id': i,
            'label': `${ res.data.data[i].code} - ${res?.data.data[i].name}`,
          });
        }

        setDataBank(dataPush);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const doGetUserB2b = async () => {
    try {
      const res = await api.get(`${endpoint.MARKETPLACES_EMAIL}`, getAuthHeader);
      if (res.data.success) {
        const dataPush = [];
        for (let i = 0; i < res?.data.data.length; i += 1) {
          dataPush.push({
            'id': i,
            'label': `${ res.data.data[i]}`,
          });
        }

        setDataUserB2b(dataPush);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }
  };

  const doAdd = async () => {

    setIsLoading(true);

    const splitBankName = dataFormPayoutManual.bank_name.split(' - ');
    const bankCode = splitBankName[0];
    const bankName = splitBankName[1];

    const date = moment(dataFormPayoutManual.released_at).format('YYYY-MM-DD');
    const time = moment().format('HH:mm:ss');
    const newReleasedAt = `${date}T${time}.000Z`;

    try {
      const res = await api.post(
        `${endpoint.PAYOUT_MANUAL_WITHDRAW}`,
        {
          ...dataFormPayoutManual,
          bank_code: bankCode,
          bank_name: bankName,
          released_at: newReleasedAt
        },
        getAuthHeader
      );

      console.log(res);

      if (res.data.success) {
        toastAlert('Berhasil, mengubah status', 'success');

        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (err) {

      setIsLoading(false);

      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const handleChange = async (event) => {
    const val = event.target.value;

    setDataFormPayoutManual({
      ...dataFormPayoutManual,
      [event.target.name]: event.target.name === 'amount' ? parseFloat(val) : val,
    });
  };

  return {
    handleChange,
    dataBank,
    isLoading,
    doAdd,
    dataFormPayoutManual,
    setDataFormPayoutManual,
    dataUserB2b
  };
};
