import { Card, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

import Datatable from '../../components/datatable';
import { useGetUserB2B } from './hooks';
import { formatIDR } from '../../utils/formatNumber';

export default function UserB2BPage() {
  const cellStyle = { fontSize: 14 };

  // state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading } = useGetUserB2B({
    page: page + 1,
    page_size: rowsPerPage,
  });

  const columns = [
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Name
        </Typography>
      ),
      // width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.name}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Email
        </Typography>
      ),
      // width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.email}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Phone Number
        </Typography>
      ),
      // width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.phone_number}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Contact Person
        </Typography>
      ),
      sortable: true,
      // width: '10rem',
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.contact_person}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Balance
        </Typography>
      ),
      // width: '9rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{formatIDR(row.balance)} IDR</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          URL
        </Typography>
      ),
      sortable: true,
      // width: '16rem',
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.url}</Typography>,
    },
    // {
    //   name: (
    //     <Typography variant="subtitle2" noWrap>
    //       Action
    //     </Typography>
    //   ),
    //   sortable: true,
    //   cell: (row) => (
    //     <Label
    //       variant="filled"
    //       color={'primary'}
    //       style={{ cursor: 'pointer' }}
    //       onClick={() => {
    //         setId(row.id);
    //         download(row.id);
    //       }}
    //     >
    //       {loadingDownload && id === row.id ? 'Loading' : 'Download'}
    //     </Label>
    //   ),
    // },
  ];

  return (
    <>
      <Helmet>
        <title> User B2B | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            User B2B
          </Typography>
        </Stack>

        <Card>
          <Datatable
            columns={columns}
            data={data?.data?.data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            progressPending={isLoading}
            totalData={data?.data.total_data ?? 0}
          />
        </Card>
      </Container>
    </>
  );
}
