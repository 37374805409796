import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

export default function useSendOTP(email) {
  const [otp, setOtp] = useState('');
  //   const [email, setEmail] = useState('');
  const [button, setButton] = useState('Submit');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const postOTP = () => {
    setButton('Loading...');

    const data = {
      email,
      otp,
    };

    api
      .post(endpoint.POST_ADMIN_OTP, data)
      .then((res) => {
        if (res.data.success) {
          getDataProfile(res.data.data.access_token);

          dispatch({
            type: 'DATA_USER_ADMIN_AUTH',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('Submit');
        console.log(err);
      });
  };

  const getDataProfile = async (token = '') => {
    const getAuthHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .get(endpoint.GET_ADMIN_PROFILE, getAuthHeader)
      .then((res) => {
        setButton('Login');

        if (res.data.success) {
          dispatch({
            type: 'DATA_USER_ADMIN_PROFILE',
            data: res.data.data,
          });

          navigate('/dashboard/app');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }
        setButton('Submit');
        console.log(err);
      });
  };

  return {
    otp,
    setOtp,
    postOTP,
    button,
  };
}
