import {
  CardContent,
  CardActions,
  Card,
  Container,
  Stack,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { usePaymentFee } from './hooks';

export default function AgregatorFeePage() {
  const {
    dataPaymentFee,
    doEditPaymentFee,
    otpData,
    setOtp,
    doSubmitOtp,
    buttonOtp,
    openModalOtp,
    setOpenModalOtp,
    openModalForm,
    setOpenModalForm,
    feeName,
    setFeeName,
    feeType,
    setFeeType,
    feeAmount,
    setFeeAmount,
    viewDataPayment,
    setPlatform,
    setPlatformId,
  } = usePaymentFee();

  const fullWidth = true;

  return (
    <>
      <Helmet>
        <title> Agregator Fee | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Agregator Fee
          </Typography>
        </Stack>
        <Typography variant="h6" gutterBottom>
          Marketplaces
        </Typography>
        <Grid container spacing={3}>
          {dataPaymentFee?.marketplace !== null && Array.isArray(dataPaymentFee?.marketplace) ? (
            <>
              {dataPaymentFee?.marketplace?.map((value, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Box sx={{ maxWidth: 275 }} key={value}>
                    <Card>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Name : {value.name}
                        </Typography>
                        <Typography variant="h5" component="div">
                          Amount : {value.fee_app_amount}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Type : {value.fee_app_type}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            viewDataPayment(value);
                            setPlatform('marketplace');
                            setPlatformId(value.id);
                          }}
                        >
                          Update Fee
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <Box sx={{ maxWidth: 275, marginTop: 4, pl: 3 }}>
              <Typography variant="caption" gutterBottom sx={{ marginTop: 2 }}>
                No Data
              </Typography>
            </Box>
          )}
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
          Merchant
        </Typography>
        <Grid container spacing={3}>
          {dataPaymentFee?.merchant !== null && Array.isArray(dataPaymentFee?.merchant) ? (
            <>
              {dataPaymentFee?.merchant?.map((value, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Box sx={{ maxWidth: 275 }} key={value}>
                    <Card>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Name : {value.name}
                        </Typography>
                        <Typography variant="h5" component="div">
                          Amount : {value.fee_app_amount}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Type : {value.fee_app_type}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            viewDataPayment(value);
                            setPlatform('merchant');
                            setPlatformId(value.id);
                          }}
                        >
                          Update Fee
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </>
          ) : (
            <Box sx={{ maxWidth: 275, marginTop: 4, pl: 3 }}>
              <Typography variant="caption" gutterBottom sx={{ marginTop: 2 }}>
                No Data
              </Typography>
            </Box>
          )}
        </Grid>

        <Dialog fullWidth={fullWidth} maxWidth={'xs'} open={openModalOtp} onClose={() => setOpenModalOtp(false)}>
          <DialogTitle style={{ fontSize: 16, fontWeight: 400 }}>
            Permintaan mengubah Payment Fee, Silahkan cek email "<b>development@invoiceplus.id</b>" untuk mendapatkan
            kode otp
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" onClick={doSubmitOtp}>
              {buttonOtp}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openModalForm} onClose={() => setOpenModalForm(false)}>
          <DialogTitle>Update Fee</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mb: 2, mt: 1 }}
              label="Fee Name"
              variant="outlined"
              style={{ width: '100%' }}
              defaultValue={feeName}
              onChange={(e) => setFeeName(e.target.value)}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Fee Type ({feeType === 'nominal' ? 'Rp' : '%'})</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={feeType}
                label="Fee Type"
                onChange={(e) => setFeeType(e.target.value)}
              >
                <MenuItem value={'percentage'}>Percentage</MenuItem>
                <MenuItem value={'nominal'}>Nominal</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ mb: 2 }}
              label="Amount"
              variant="outlined"
              style={{ width: '100%' }}
              defaultValue={feeAmount}
              type="number"
              InputProps={{ step: 0.1 }}
              onChange={(e) => setFeeAmount(e.target.value)}
            />
            <TextField
              sx={{ mb: 2 }}
              label="OTP"
              variant="outlined"
              defaultValue={otpData}
              onChange={(e) => setOtp(e.target.value)}
              style={{ width: '100%' }}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" onClick={doEditPaymentFee}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
