import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import { useSearchParams } from 'react-router-dom';
import useSendOTP from '../../hooks/auth/useSendOTP';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function OTPPage() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const { otp, setOtp, postOTP, button } = useSendOTP(email);

  return (
    <>
      <Helmet>
        <title> OTP | Saas Invoice & Billing </title>
      </Helmet>

      <Container maxWidth="sm">
        <StyledRoot>
          <StyledContent>
            <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
              OTP Verification
            </Typography>
            <Typography gutterBottom sx={{ mb: 6 }}>
              Please check your email to get OTP code.
            </Typography>

            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{ width: '80px', height: '80px', fontSize: '60px', border: '1px solid black' }}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />

            <Button variant="contained" sx={{ mt: 6, width: 300, fontSize: 18 }} onClick={postOTP}>
              {button}
            </Button>
          </StyledContent>
        </StyledRoot>
      </Container>
    </>
  );
}
