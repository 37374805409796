import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, TextField, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  emailData: PropTypes.string.isRequired,
  setEmailData: PropTypes.func.isRequired,
  postForgotPassword: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
};

export default function ResetPasswordForm({ emailData, setEmailData, postForgotPassword, button }) {
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={emailData}
          onChange={(e) => setEmailData(e.target.value)}
          name="email"
          label="Enter your email address"
        />
      </Stack>

      <br />

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postForgotPassword}>
        {button}
      </LoadingButton>

      <br />

      <Typography variant="body2" sx={{ mb: 5 }}>
        Already have an account? {''}
        <Link style={{ cursor: 'pointer' }} onClick={() => navigate('/login')} variant="subtitle2">
          Login here
        </Link>
      </Typography>
    </>
  );
}
