// @mui
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

AddFormView.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  buttonClose: PropTypes.string,
  list: PropTypes.array.isRequired,
  postDataTicketReply: PropTypes.func,
  dataFormTicketReply: PropTypes.func,
  postDataFileImage: PropTypes.func,
  loadingImage: PropTypes.bool,
  handleChangeTicketReply: PropTypes.func,
  status: PropTypes.bool,
  buttonReply: PropTypes.bool,
  postDataTicketClose: PropTypes.func,
};

export default function AddFormView({
  title,
  subheader,
  handleChangeTicketReply,
  status,
  buttonReply,
  postDataTicketReply,
  dataFormTicketReply,
  postDataTicketClose,
  postDataFileImage,
  buttonClose,
  loadingImage,
  ...other
}) {
  return (
    <Card {...other}>
      <Label variant="filled" color={'info'}>
        {status}
      </Label>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={10}>
          <CardHeader title={title} subheader={subheader} />
        </Grid>
        {status !== 'closed' && (
          <Grid item xs={12} sm={6} md={2} align="right">
            <Label
              variant="filled"
              color={'warning'}
              style={{ backgroundColor: 'red', color: '#fff', position: 'relative', top: 18, right: 22 }}
              onClick={postDataTicketClose}
            >
              <Iconify icon="zondicons:close-solid" />
              &nbsp;&nbsp; {buttonClose}
            </Label>
          </Grid>
        )}
      </Grid>

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  name="message"
                  label="Type here"
                  multiline
                  rows={6}
                  maxRows={4}
                  value={dataFormTicketReply.message}
                  onChange={(e) => handleChangeTicketReply(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Upload File (optional)"
                  type="file"
                  onChange={(event) => postDataFileImage(event)}
                />
              </Stack>
              {loadingImage && (
                <Typography sx={{ color: '#adadad' }} variant="body2">
                  Loading upload...
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postDataTicketReply}>
                  {buttonReply}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
