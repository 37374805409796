import { toast } from 'react-toastify';
import ucfirst from 'ucfirst';

export default function toastAlert(message, alert) {
  if (alert === 'success') {
    toast.success(ucfirst(message), {
      position: 'top-right',
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  if (alert === 'error') {
    toast.error(ucfirst(message), {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}
