import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';
// @mui
// sections
import { List } from './sections';
// hooks
import useServices from './hooks/useServices';

// ----------------------------------------------------------------------

export default function ServicesListPage() {
  const { dataServices, getDataServices, dataTotalTable } = useServices();

  return (
    <>
      <Helmet>
        <title> Services: List | SASS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Services
          </Typography>
        </Stack>

        <Card>
          <List dataServices={dataServices} getDataServices={getDataServices} dataTotalTable={dataTotalTable} />
        </Card>
      </Container>
    </>
  );
}
