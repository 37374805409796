import { Avatar, Box, Card, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import moment from 'moment';

import Label from '../../components/label';

import Datatable from '../../components/datatable';
import { useDownloadBills, useGetBills } from './hooks';
import { formatIDR } from '../../utils/formatNumber';

export default function BillsPage() {
  const cellStyle = { fontSize: 14 };

  // state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState();

  const { data, isLoading } = useGetBills({
    page: page + 1,
    page_size: rowsPerPage,
  });
  const { download, isLoading: loadingDownload } = useDownloadBills();

  const columns = [
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          No
        </Typography>
      ),
      width: '4rem',
      cell: (_, i) => <Typography sx={{ ...cellStyle }}>{i + 1}.</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Date
        </Typography>
      ),
      width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{moment(row.created_at).format('LLLL')}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Bill Number
        </Typography>
      ),
      width: '8rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.bill_number}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Company
        </Typography>
      ),
      sortable: true,
      width: '16rem',
      cell: (row) =>
        row.company ? (
          <Box display="flex" alignItems="center">
            <Avatar src={row.company.logo} alt="photoURL" sx={{ marginRight: 2 }} />
            <Box>
              <Typography fontWeight="bold">{row.company.name}</Typography>
              <Typography fontSize={14} color="#9eabc0">
                {row.company.email}
              </Typography>
            </Box>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Grand Total
        </Typography>
      ),
      width: '10rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{formatIDR(row.grand_total)} IDR</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Status
        </Typography>
      ),
      sortable: true,
      cell: (row) => {
        let buttonColor = 'primary';
        if (row.status === 'paid') {
          buttonColor = 'success';
        } else if (row.status === 'sent') {
          buttonColor = 'warning';
        } else if (row.status === 'unsend') {
          buttonColor = 'error';
        }

        return (
          <Label
            variant="filled"
            color={buttonColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              color: '#fff',
            }}
          >
            {row.status}
          </Label>
        );
      },
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Action
        </Typography>
      ),
      sortable: true,
      cell: (row) => (
        <Label
          variant="filled"
          color={'primary'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setId(row.id);
            download(row.id);
          }}
        >
          {loadingDownload && id === row.id ? 'Loading' : 'Download'}
        </Label>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Bills | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Bills
          </Typography>
        </Stack>

        <Card>
          <Datatable
            columns={columns}
            data={data?.data?.data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            progressPending={isLoading}
            totalData={data?.data.total_data ?? 0}
          />
        </Card>
      </Container>
    </>
  );
}
