import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Container, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-toastify/dist/ReactToastify.css';
// hooks
// components
import useChangePassword from '../../hooks/auth/useChangePassword';

export default function ChangePasswordPage() {
  const { userProfile } = useSelector((states) => ({
    userProfile: states?.UserAdminReducer?.dataUserAdminProfile,
  }));

  const {
    newPassword,
    setNewPassword,
    newPasswordRepetition,
    setNewPasswordRepetition,
    oldPassword,
    setOldPassword,
    postForgotPassword,
    button,
  } = useChangePassword(userProfile.email);

  return (
    <>
      <Helmet>
        <title> Reset Password | Saas Invoice & Billing </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          Change Password
        </Typography>

        <Stack spacing={3} sx={{ mt: 3, width: 400 }}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            name="email"
            label="Enter old password"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            name="email"
            label="Enter new password"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            value={newPasswordRepetition}
            onChange={(e) => setNewPasswordRepetition(e.target.value)}
            name="email"
            label="Enter new password confirmation"
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postForgotPassword}>
            {button}
          </LoadingButton>
        </Stack>
      </Container>
    </>
  );
}
