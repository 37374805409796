import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

export const usePaymentFee = () => {
  const navigate = useNavigate();
  const [openModalOtp, setOpenModalOtp] = useState(false);
  const [otpData, setOtp] = useState('');
  const [buttonOtp, setButtonOtp] = useState('Submit');
  const [dataPaymentFee, setDataPaymentFee] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [feeEmail, setFeeEmail] = useState('');
  const [feeName, setFeeName] = useState('');
  const [feeType, setFeeType] = useState('');
  const [feeAmount, setFeeAmount] = useState('');

  useEffect(() => {
    doGetPaymentFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGetPaymentFee = async () => {
    try {
      const res = await api.get(`${endpoint.PAYMENT}/fee`, getAuthHeader);

      if (res.data.success) {
        setDataPaymentFee(res.data.data);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const doSubmitOtp = async () => {
    try {
      const dataForm = {
        template_name: 'sms_otp',
        via: 'email',
      };

      setButtonOtp('Loading...');

      const res = await api.post(`${endpoint.POST_ADMIN_REQUEST_OTP}`, dataForm, getAuthHeader);

      if (res.data.success) {
        console.log('res data', res);
        setOpenModalForm(true);
        setOpenModalOtp(false);
        setButtonOtp('Submit');
      }
    } catch (err) {
      setButtonOtp('Submit');
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const doEditPaymentFee = async () => {
    try {
      const dataForm = {
        email: '',
        fee_amount: parseFloat(feeAmount),
        fee_name: feeName,
        fee_type: feeType,
        otp: otpData,
      };

      const res = await api.put(`${endpoint.PAYMENT}/fee`, dataForm, getAuthHeader);

      if (res.data.success) {
        toastAlert(res.data.message, 'success');

        setOpenModalForm(false);
        setOtp('');
        doGetPaymentFee();
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const viewDataPayment = (value) => {
    setFeeName(value.name);
    setFeeType(value.type);
    setFeeAmount(value.amount);

    setOpenModalOtp(true);
  };

  return {
    dataPaymentFee,
    doEditPaymentFee,
    otpData,
    setOtp,
    doSubmitOtp,
    buttonOtp,
    openModalOtp,
    setOpenModalOtp,
    openModalForm,
    setOpenModalForm,
    feeEmail,
    setFeeEmail,
    feeName,
    setFeeName,
    feeType,
    feeAmount,
    setFeeAmount,
    viewDataPayment,
    setFeeType,
  };
};
