import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  passwordData: PropTypes.string.isRequired,
  setPasswordData: PropTypes.func.isRequired,
  postLogin: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
};

export default function LoginForm({ username, setUsername, passwordData, setPasswordData, postLogin, button }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          name="email"
          label="Email address"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={passwordData}
          onChange={(e) => setPasswordData(e.target.value)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link
          style={{ cursor: 'pointer' }}
          variant="subtitle2"
          underline="hover"
          onClick={() => navigate('/password/reset/')}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postLogin}>
        {button}
      </LoadingButton>

      <br />
    </>
  );
}
