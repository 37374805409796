import { Box, Card, CardHeader, CardContent, Grid, Stack, TextField, Autocomplete } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';

import { useGetGatewayPayoutAdd } from './hooks';

export default function GatewayPayoutRequestDetailPage() {

  const { handleChange, dataBank, doAdd, isLoading, dataFormPayoutManual, setDataFormPayoutManual, dataUserB2b } = useGetGatewayPayoutAdd();

  return (
    <>
      <Helmet>
        <title> Marketplace Payout Manual Withdraw | SAAS Invoice </title>
      </Helmet>

      <Card>
        <CardHeader title={'Form'} subheader={'Entri Manual Withdraw'} />
          <CardContent>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <Autocomplete
                      name="agregator_email"
                      disablePortal
                      id="combo-box-demo"
                      options={dataUserB2b}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Agregator Email" />}
                      onChange={(e) => {
                        setDataFormPayoutManual({
                          ...dataFormPayoutManual,
                          agregator_email: e.target.innerHTML
                        })
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="sender"
                      label="Sender"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <Autocomplete
                      name="bank_name"
                      disablePortal
                      id="combo-box-demo"
                      options={dataBank}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} label="Bank Name" />}
                      onChange={(e) => {
                        setDataFormPayoutManual({
                          ...dataFormPayoutManual,
                          bank_name: e.target.innerHTML
                        })
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="bank_branch"
                      label="Bank Branch"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="bank_account"
                      label="Account Name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="bank_account_name"
                      label="Bank Account Name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="amount"
                      label="Amount"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      type='date'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="released_at"
                      label="Released At"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="transfer_order_number"
                      label="Transfer Order Number"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={3}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      maxRows={10}
                      name="note"
                      label="Note"
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={3}>
                    {isLoading ? (
                      <LoadingButton fullWidth size="large" type="submit" variant="outlined">
                        Loading...
                      </LoadingButton>
                    ) : (
                      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={doAdd}>
                        Submit
                      </LoadingButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
      </Card>
    </>
  );
}
