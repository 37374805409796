import { Avatar, Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import moment from 'moment';

import Label from '../../components/label';

import Datatable from '../../components/datatable';
import { useChangeSettlement, useGetInvoices } from './hooks';
import { formatIDR } from '../../utils/formatNumber';

export default function SettlementB2BPage() {
  const cellStyle = { fontSize: 14 };

  // state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState();

  const { data, isLoading, refetch } = useGetInvoices({
    page: page + 1,
    page_size: rowsPerPage,
  });
  const { changeSettle, isLoading: loadingDownload, result } = useChangeSettlement();

  useEffect(() => {
    if (result) {
      refetch();
    }
  }, [result, refetch]);

  const columns = [
    // {
    //   name: (
    //     <Typography variant="subtitle2" noWrap>
    //       No
    //     </Typography>
    //   ),
    //   width: '4rem',
    //   cell: (_, i) => <Typography sx={{ ...cellStyle }}>{i + 1}.</Typography>,
    // },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Date
        </Typography>
      ),
      width: '7rem',
      wrap: true,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{moment(row.created_at).format('LLLL')}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Invoice Number</Typography>,
      width: '8rem',
      wrap: true,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.invoice_number}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Sender
        </Typography>
      ),
      sortable: true,
      width: '12rem',
      wrap: true,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar src={row.sender_logo} alt="photoURL" sx={{ marginRight: 2 }} />
          <Box>
            <Typography fontWeight="bold">{row.sender_name}</Typography>
            <Typography fontSize={14} color="#9eabc0">
              {row.sender_email}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Customer
        </Typography>
      ),
      sortable: true,
      width: '12rem',
      wrap: true,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar src={row.customer_logo ?? ''} alt="photoURL" sx={{ marginRight: 2 }} />
          <Box>
            <Typography fontWeight="bold">{row.customer_name}</Typography>
            <Typography fontSize={14} color="#9eabc0">
              {row.customer_email}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Grand Total
        </Typography>
      ),
      width: '9rem',
      wrap: true,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{formatIDR(row.total_invoice)} IDR</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Status
        </Typography>
      ),
      sortable: true,
      width: '8rem',
      cell: (row) => {
        let buttonColor = 'primary';
        if (row.status === 'paid') {
          buttonColor = 'success';
        } else if (row.status === 'sent') {
          buttonColor = 'warning';
        } else if (row.status === 'unsend') {
          buttonColor = 'error';
        }

        return (
          <Label
            variant="filled"
            color={buttonColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              color: '#fff',
            }}
          >
            {row.status}
          </Label>
        );
      },
    },
    {
      name: <Typography variant="subtitle2">IsSettled</Typography>,
      sortable: true,
      width: '6rem',
      cell: (row) => {
        let buttonColor = 'primary';
        if (row.is_settled) {
          buttonColor = 'success';
        } else {
          buttonColor = 'error';
        }

        return (
          <Label
            variant="filled"
            color={buttonColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              color: '#fff',
            }}
          >
            {row.is_settled ? 'Yes' : 'No'}
          </Label>
        );
      },
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Action
        </Typography>
      ),
      sortable: true,
      cell: (row) => (
        <Button
          variant="contained"
          color={'primary'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setId(row.id);
            changeSettle(row.id);
          }}
        >
          {loadingDownload && id === row.id ? 'Loading' : 'Change'}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Settlement B2B | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Settlement B2B
          </Typography>
        </Stack>

        <Card>
          <Datatable
            columns={columns}
            data={data?.data?.data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            progressPending={isLoading}
            totalData={data?.data.total_data ?? 0}
          />
        </Card>
      </Container>
    </>
  );
}
