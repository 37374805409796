// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Card, Typography, Avatar } from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';

import moment from 'moment';
// components
import Scrollbar from '../../../components/scrollbar';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

Comment.propTypes = {
  dataTicketDetail: PropTypes.func,
};

export default function Comment({ dataTicketDetail, ...other }) {
  const data = dataTicketDetail.ticket_details;

  return (
    <Card {...other}>
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: 10 }}>
            Note: <b>U</b> = User Active, <b>A</b> = Admin
          </Typography>
          {data.map((value) => (
            <CommentItem key={value.id} value={value} />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

CommentItem.propTypes = {
  value: PropTypes.shape({
    file: PropTypes.string,
    sender: PropTypes.string,
    chat: PropTypes.string,
    created_at: PropTypes.instanceOf(Date),
  }),
};

function CommentItem({ value }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {value.sender === 'user' && <Avatar sx={{ bgcolor: deepOrange[500] }}>U</Avatar>}
      {value.sender !== 'user' && <Avatar sx={{ bgcolor: deepPurple[500] }}>A</Avatar>}

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {value.chat}
        </Typography>
        {value.file && (
          <>
            <Label
              variant="filled"
              color={'info'}
              sx={{
                textTransform: 'uppercase',
                color: '#fff',
                fontSize: 8,
              }}
              onClick={() => {
                window.open(value.file, '_blank');
              }}
            >
              Download File &nbsp;&nbsp;
              <Iconify width={16} height={16} style={{ color: '#fff' }} icon={'solar:download-bold'} />
            </Label>
            <br />
          </>
        )}
        <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: 8 }}>
          {moment(value.created_at).format('LLLL')}
        </Typography>
      </Box>
    </Stack>
  );
}
