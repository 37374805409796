import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';
import { paramsToQuery } from '../../utils/formatParamsToQuery';

// get invoice
export const useGetInvoices = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.SETTLEMENT_B2B}/admin?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getInvoices', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// change settlement
export const useChangeSettlement = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);
  const [result, setResult] = useState(false);

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const changeSettle = async (id) => {
    setResult(false);
    setIsloading(true);
    try {
      const res = await axios.put(`${endpoint.SETTLEMENT_B2B}/admin/${id}/settled`, null, getAuthHeader);

      if (res.data.success) {
        toastAlert(res.data.message, 'success');
        setResult(true);
      }
    } catch (err) {
      toastAlert(err.response.statusText, 'error');
      setResult(false);

      if (err.response.data.error_code === 'E-00050') {
        setTimeout(() => {
          navigate('/login');

          dispatch({
            type: 'DATA_USER_ADMIN_AUTH_EMPTY',
          });
        }, 500);
      }
    } finally {
      setIsloading(false);
      // setResult(false);
    }

    return null;
  };

  return { changeSettle, isLoading, result };
};
