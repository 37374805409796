const baseUrl = process.env.REACT_APP_BASE_URL_ADMIN;
const gatewayUrl = process.env.REACT_APP_BASE_URL_GATEWAY;

const endpoint = {
  // USER (AUTH)
  POST_LOGIN: `${baseUrl}auth/login`,
  POST_REGISTER: `${baseUrl}auth/register`,
  POST_FORGOT_PASSWORD: `${baseUrl}auth/forgot-password`,
  // FILE
  POST_UPLOAD_FILE: `${baseUrl}files`,

  // POST ADMIN
  POST_ADMIN_LOGIN: `${baseUrl}auth/admin/login`,
  POST_ADMIN_FORGOT_PASSWORD: `${baseUrl}auth/admin/forgot-password`,
  POST_ADMIN_OTP: `${baseUrl}auth/admin/otp/validation`,
  POST_ADMIN_CHANGE_PASSWORD: `${baseUrl}auth/admin/change-password`,
  POST_ADMIN_REQUEST_OTP: `${baseUrl}otp/admin`,

  // USER
  GET_ADMIN_PROFILE: `${baseUrl}admins`,
  // TICKETS
  TICKETS: `${baseUrl}tickets/admin`,
  // COMPANY
  COMPANY: `${baseUrl}companies/admin`,
  // CONTACT
  CONTACT: `${baseUrl}contacts/admin`,
  // SERVICES
  SERVICES: `${baseUrl}services/admin`,
  // INVOICE
  INVOICES: `${baseUrl}invoices`,
  // BILLS
  BILLS: `${baseUrl}bills`,
  // PAYOUT
  PAYOUT: `${baseUrl}payouts`,
  // PAYLATER_RULES
  PAYLATER_RULES: `${baseUrl}paylater-rules`,
  // TIERS
  PAYLATER_TIERS: `${baseUrl}paylater-tiers`,
  // gateway payout
  GATEWAY_PAYOUT: `${gatewayUrl}payouts`,
  // gateway fee
  GATEWAY_FEE: `${gatewayUrl}fees`,
  GATEWAY: `${gatewayUrl}`,
  // Settlement b2b
  SETTLEMENT_B2B: `${gatewayUrl}invoices`,
  PAYMENT: `${baseUrl}payments`,
  // DASHBOARD B2C
  DASHBOARD: `${baseUrl}agregate/admin/dashboard`,
  // DASHBOARD B2B
  DASHBOARD_B2B: `${gatewayUrl}dashboard/user`,
  // Agreagator fee
  AGREGATOR_FEE: `${gatewayUrl}fees/platform`,
  // Payout Bank
  PAYOUT_BANK: `${gatewayUrl}payouts/bank`,
  // Payout Bank
  PAYOUT_MANUAL_WITHDRAW: `${gatewayUrl}payouts/admin/confirm-manual`,
  // User B2B
  MARKETPLACES_EMAIL: `${gatewayUrl}marketplaces/email`,
};

export default endpoint;
