import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import Page404 from './pages/Page404';
import DashboardAppPage from './pages/dashboard';

// auth
import LoginPage from './pages/auth/LoginPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';

// dashboard
// import DashboardAppPageAdmin from './pages/dashboard/DashboardAppPage';
// ticket
import TicketListPage from './pages/ticket/TicketListPage';
import TicketViewPage from './pages/ticket/TicketViewPage';
// contact
import ContactListPage from './pages/contact/ContactListPage';
// company
import CompanyListPage from './pages/company/CompanyListPage';
// services
import ServicesListPage from './pages/services/ServicesListPage';
// payout
import PayoutSettingMethodPage from './pages/payout/setting/methods';
import PayoutSettingRequestPage from './pages/payout/setting/request';
import PayoutSettingRequestDetailPage from './pages/payout/setting/request/detail';
import PayoutSettingLogPage from './pages/payout/setting/log';
import PayoutVendorMethodPage from './pages/payout/vendor/methods';
import PayoutVendorRequestPage from './pages/payout/vendor/request';
import PayoutVendorDetailRequestPage from './pages/payout/vendor/request/detail';
import PayoutVendorLogPage from './pages/payout/vendor/log';
import OTPPage from './pages/auth/OTPPage';
import ChangePasswordPage from './pages/auth/ChangePassword';
import InvoicesPage from './pages/invoices';
import BillsPage from './pages/bills';
import GatewayPage from './pages/gateway';
import GatewayPayoutRequestDetailPage from './pages/gateway/detail';
import GatewayRequestLogPage from './pages/gateway/log';
import PaymentFeePage from './pages/payment-fee';
import DashboardB2b from './pages/dashboard-b2b';
import PaylaterRules from './pages/paylater/rules';
import PaylaterTiers from './pages/paylater/tiers';
import PaymentFeeB2BPage from './pages/payment-fee-b2b';
import AgregatorFeePage from './pages/agregator-fee';
import SettlementB2BPage from './pages/settlement-b2b';
import UserB2BPage from './pages/user-b2b';
import GatewayPayoutRequestAddPage from './pages/gateway/add';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [{ path: 'app', element: <DashboardAppPage /> }],
    },
    {
      path: '/ticket',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <TicketListPage />,
        },
        {
          path: 'view',
          element: <TicketViewPage />,
        },
      ],
    },
    {
      path: '/company',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <CompanyListPage />,
        },
      ],
    },
    {
      path: '/contact',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <ContactListPage />,
        },
      ],
    },
    {
      path: '/services',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <ServicesListPage />,
        },
      ],
    },
    {
      path: '/paylater',
      element: <DashboardLayout />,
      children: [
        {
          path: 'rules',
          element: <PaylaterRules />,
        },
        {
          path: 'tiers',
          element: <PaylaterTiers />,
        },
      ],
    },
    {
      path: '/payouts',
      element: <DashboardLayout />,
      children: [
        {
          path: 'setting/method/list',
          element: <PayoutSettingMethodPage />,
        },
        {
          path: 'setting/request/list',
          children: [
            { index: true, element: <PayoutSettingRequestPage /> },
            {
              path: ':id',
              element: <PayoutSettingRequestDetailPage />,
            },
          ],
        },
        {
          path: 'setting/log/list',
          element: <PayoutSettingLogPage />,
        },
        {
          path: 'vendor/method/list',
          element: <PayoutVendorMethodPage />,
        },
        {
          path: 'vendor/request/list',
          children: [
            { index: true, element: <PayoutVendorRequestPage /> },
            {
              path: ':id',
              element: <PayoutVendorDetailRequestPage />,
            },
          ],
        },

        {
          path: 'vendor/log/list',
          element: <PayoutVendorLogPage />,
        },
      ],
    },
    {
      path: '/settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'change-password',
          element: <ChangePasswordPage />,
        },
      ],
    },
    {
      path: '/gateway',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          children: [
            { index: true, element: <GatewayPage /> },
            {
              path: ':id',
              element: <GatewayPayoutRequestDetailPage />,
            },
          ],
        },
        {
          path: 'log',
          element: <GatewayRequestLogPage />,
        },
      ],
    },
    {
      path: '/gateway',
      element: <DashboardLayout />,
      children: [
        {
          path: 'add',
          element: <GatewayPayoutRequestAddPage />,
        },
      ],
    },
    {
      path: '/invoices',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <InvoicesPage />,
        },
      ],
    },
    {
      path: '/settlement-b2b',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <SettlementB2BPage />,
        },
      ],
    },
    {
      path: '/user-b2b',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <UserB2BPage />,
        },
      ],
    },
    {
      path: '/bills',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <BillsPage />,
        },
      ],
    },
    {
      path: '/agregator-fee',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <AgregatorFeePage />,
        },
      ],
    },
    {
      path: '/payment-fee',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PaymentFeePage />,
        },
      ],
    },
    {
      path: '/payment-fee-b2b',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PaymentFeeB2BPage />,
        },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'b2b',
          element: <DashboardB2b />,
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'otp',
      element: <OTPPage />,
    },
    {
      path: 'password/reset',
      element: <ResetPasswordPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
