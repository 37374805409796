import { Box, Button, ButtonGroup, Card, Container, Grid, Typography, Stack, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import OTPInput from 'react-otp-input';

import { useGetDetailPayoutReqCompany, useConfirmPayoutReq } from './hooks';
import { formatIDR } from '../../../../utils/formatNumber';
import useRequestOTP from '../../../../hooks/auth/useRequestOTP';

export default function PayoutSettingRequestDetailPage() {
  const [isActiveButton, setIsActiveButton] = useState('');
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState('');
  const [otp, setOtp] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  const { mutate: doConfirm, isLoading: isLoadingConfirm } = useConfirmPayoutReq();
  const { dataReqDetail, refetch } = useGetDetailPayoutReqCompany();
  const { postRequestOTP, showOtp, setShowOtp } = useRequestOTP();

  return (
    <>
      <Helmet>
        <title> Payout Request | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Card>
          <Box paddingY={2} paddingX={3} display="flex" alignItems="center" justifyContent="space-between">
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Typography variant="h4" gutterBottom>
                  Payout Details
                </Typography>
              </Grid>
              <Grid item md={6} style={{ textAlign: 'right' }}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  <Button onClick={() => navigate(-1)}>Back</Button>
                  {dataReqDetail?.status === 'released' || dataReqDetail?.status === 'cancel' ? null : (
                    <>
                      <Button
                        disabled={isLoadingConfirm}
                        variant="contained"
                        onClick={() => {
                          setOpen(true);
                          setIsActiveButton('released');
                        }}
                      >
                        {isLoadingConfirm && isActiveButton === 'released' ? 'Loading...' : 'Confirm'}
                      </Button>
                      <Button
                        disabled={isLoadingConfirm}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setOpen(true);
                          setIsActiveButton('cancel');
                        }}
                      >
                        {isLoadingConfirm && isActiveButton === 'cancel' ? 'Loading...' : 'Reject'}
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    Transaction : {moment(dataReqDetail.updated_at).format('D/MM/YYYY')}
                  </Grid>
                  <Grid item md={12}>
                    Company Name : {dataReqDetail.company.name}
                  </Grid>
                  <Grid item md={12}>
                    Sender Name : {dataReqDetail.sender_name}
                  </Grid>
                  <Grid item md={12}>
                    Transaction Id : <span style={{ textTransform: 'uppercase' }}>{dataReqDetail.transaction_id}</span>
                  </Grid>
                  <Grid item md={12}>
                    Status : {dataReqDetail.status}
                  </Grid>
                  <Grid item md={12}>
                    Amount : {formatIDR(dataReqDetail.amount)} IDR
                  </Grid>
                  <Grid item md={12}>
                    Charge : {formatIDR(dataReqDetail.fee)} IDR
                  </Grid>
                  <Grid item md={12}>
                    Total Amount : {formatIDR(dataReqDetail.total)} IDR
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography variant="h6" gutterBottom>
                      Withdraw Information
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    Bank Currency : {dataReqDetail.payment_method}
                  </Grid>
                  <Grid item md={12}>
                    Name : {dataReqDetail.account_name}
                  </Grid>
                  <Grid item md={12}>
                    Account Number : {dataReqDetail.account_number}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
        {/* dialog add notes */}
        <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <Stack spacing={2} mt={2}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                name="account_name"
                label="Note"
                multiline
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);

                postRequestOTP();
              }}
              variant="outlined"
              color="info"
            >
              Submit
            </Button>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* dialog otp */}
        <Dialog fullWidth maxWidth="sm" open={showOtp} onClose={() => setShowOtp(false)}>
          <DialogTitle>OTP Verification</DialogTitle>

          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 2 }}
          >
            <Typography gutterBottom sx={{ mb: 6 }}>
              Please check your email to get OTP code.
            </Typography>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{ width: '80px', height: '80px', fontSize: '60px', border: '1px solid black' }}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </DialogContent>

          <DialogActions sx={{ p: 4 }}>
            <Button
              onClick={() => {
                doConfirm(
                  {
                    id: params.id,
                    note,
                    status: isActiveButton,
                    otp,
                    setShowOtp,
                  },
                  {
                    onSuccess: () => {
                      refetch();

                      setTimeout(() => {
                        navigate('/payouts/vendor/request/list');
                      }, 2500);
                    },
                  }
                );
              }}
              variant="outlined"
              color="info"
            >
              {isLoadingConfirm ? 'Please wait...' : 'Submit'}
            </Button>
            <Button onClick={() => setShowOtp(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
