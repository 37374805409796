import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';

const useContact = () => {
  const [dataContact, setDataContact] = useState([]);
  const pageSizeTable = 10;
  const [statusTable, setStatusTable] = useState('vendor');
  const [dataTotalTable, setTotalDataTable] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
  }));

  useEffect(() => {
    getDataContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const getDataContact = async (newPage = 1, filterTable = '') => {
    api
      .get(`${endpoint.CONTACT}/${statusTable}?page_size=${pageSizeTable}&&page=${newPage}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataContact.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataContact([...dataContact, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataContact(dataRes);
          }

          setTotalDataTable(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');

          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_ADMIN_AUTH_EMPTY',
              });

              navigate('/login');
            }, 500);
          }
        }
      });
  };

  const submitSearch = () => {
    getDataContact(1, 'filter');
  };

  return {
    dataContact,
    getDataContact,
    dataTotalTable,
    submitSearch,
    setStatusTable,
  };
};

export default useContact;
