import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';
import { paramsToQuery } from '../../../utils/formatParamsToQuery';

// get paylater rules
export const useGetPaylaterRules = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.PAYLATER_RULES}/list?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getPaylaterRules', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 60 * 60 * 60,
  });
};

// get paylater rules by id
export const useGetOnePaylaterRules = (id) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const res = await api.get(`${endpoint.PAYLATER_RULES}/${id}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getOnPaylaterRules', id],
    queryFn: () => doGet(),
    keepPreviousData: false,
    staleTime: 0,
    enabled: Boolean(id),
  });
};

// create paylater rules
const useCreatePaylaterRules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doAction = async (payload) => {
    try {
      const res = await api.post(`${endpoint.PAYLATER_RULES}/new`, payload, getAuthHeader);

      if (res.data.success) {
        toastAlert('Success create paylater rules', 'success');
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useMutation(doAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPaylaterRules']);
    },
  });
};

// edit paylater rules
const useEditPaylaterRules = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doAction = async (payload) => {
    try {
      const res = await api.put(`${endpoint.PAYLATER_RULES}/${id}/update`, payload, getAuthHeader);

      if (res.data.success) {
        toastAlert('Success edit paylater rules', 'success');
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useMutation(doAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPaylaterRules']);
    },
  });
};

// edit paylater rules
const useDeletePaylaterRules = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doAction = async () => {
    try {
      const res = await api.delete(`${endpoint.PAYLATER_RULES}/${id}/delete`, getAuthHeader);

      if (res.data.success) {
        toastAlert('Success delete paylater rules', 'success');
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useMutation(doAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPaylaterRules']);
    },
  });
};

// hooks for create or edit
export const useCreateEditPaylaterRules = ({ closeForm, id }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    reset,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      loan_type: 'monthly',
    },
  });

  const handleClose = (setOpen) => {
    setOpen(false);
    clearErrors();
    reset({
      accelerated_repayment_fee: '',
      copy_invoice_fee: '',
      duty_stamp_fee: '',
      duty_stamp_threshold: '',
      loan_fee_rate: '',
      loan_late_fee_rate: '',
      loan_period: '',
    });
  };

  const { mutate, isLoading: loadingCreate } = useCreatePaylaterRules();
  const { mutate: doEdit, isLoading: loadingEdit } = useEditPaylaterRules(id);

  const onCreate = (data) => {
    mutate(data, {
      onSuccess: () => handleClose(closeForm),
    });
  };
  const onEdit = (data) => {
    doEdit(data, {
      onSuccess: () => handleClose(closeForm),
    });
  };

  const handleCreateEdit = (formType) => {
    handleSubmit((formData) => {
      if (formType === 'Create') {
        onCreate(formData);
      } else {
        onEdit(formData);
      }
    })();
  };

  return {
    register,
    errors,
    handleClose,
    handleCreateEdit,
    loadingCreate,
    loadingEdit,
    reset,
  };
};

// hooks for index paylater rules
export const useIndexPaylaterRules = () => {
  // state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToAction, setIdToAction] = useState(null);

  const { data, isLoading } = useGetPaylaterRules({
    page: page + 1,
    page_size: rowsPerPage,
  });

  const { mutate: doDelete, isLoading: loadingDelete } = useDeletePaylaterRules(idToAction);
  const handleDelete = () => {
    doDelete(undefined, {
      onSuccess: () => {
        setShowDelete(false);
      },
    });
  };

  return {
    data,
    isLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    showCreate,
    setShowCreate,
    showEdit,
    setShowEdit,
    showDelete,
    setShowDelete,
    open,
    setOpen,
    idToAction,
    setIdToAction,
    doDelete,
    loadingDelete,
    handleDelete,
  };
};
