import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';
import { paramsToQuery } from '../../utils/formatParamsToQuery';

// get user b2b
export const useGetUserB2B = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.GATEWAY}marketplaces?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getUserB2B', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};
