export function saveAs(data, name) {
  // Create a Blob from the response data
  const blob = new Blob([data], { type: 'application/pdf' });

  // Create a link element
  const link = document.createElement('a');

  // Set the download attribute and create a data URI
  link.download = name;
  link.href = window.URL.createObjectURL(blob);

  // Append the link to the document body
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document body
  document.body.removeChild(link);
}
