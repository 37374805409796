import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useCreateEditPaylaterRules, useGetOnePaylaterRules } from './hooks';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

FormCreateRules.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formType: PropTypes.oneOf(['Create', 'Edit']),
  id: PropTypes.string,
};

export default function FormCreateRules({ open, setOpen, formType, id }) {
  const { register, errors, handleClose, handleCreateEdit, loadingCreate, reset, loadingEdit } =
    useCreateEditPaylaterRules({
      closeForm: setOpen,
      id,
    });

  const { data } = useGetOnePaylaterRules(id);

  React.useEffect(() => {
    reset(data?.data);
  }, [data, reset]);

  return (
    <>
      <BootstrapDialog
        onClose={() => handleClose(setOpen)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {formType} Paylater Rule
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(setOpen)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <FormLabel>Type</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <RadioGroup name="loan_type" defaultValue={'monthly'}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* <FormControlLabel value="daily" control={<Radio />} label="Daily" {...register('loan_type')} /> */}
                  <FormControlLabel value="monthly" control={<Radio />} label="Monthly" {...register('loan_type')} />
                </Stack>
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Period</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('loan_period', {
                  required: 'Loan Period is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.loan_period)}
                helperText={errors.loan_period?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Fee</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('loan_fee_rate', {
                  required: 'Loan Fee Rate is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.loan_fee_rate)}
                helperText={errors.loan_fee_rate?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Late Fee</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('loan_late_fee_rate', {
                  required: 'Loan Late Fee Rate is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.loan_late_fee_rate)}
                helperText={errors.loan_late_fee_rate?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Accelerate Repayment Fee</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('accelerated_repayment_fee', {
                  required: 'Accelerated Repayment Fee is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.accelerated_repayment_fee)}
                helperText={errors.accelerated_repayment_fee?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Duty Stamp Fee</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('duty_stamp_fee', {
                  required: 'Duty Stamp Fee is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.duty_stamp_fee)}
                helperText={errors.duty_stamp_fee?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Duty Stamp Threshold</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('duty_stamp_threshold', {
                  required: 'Duty Stamp Threshold is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.duty_stamp_threshold)}
                helperText={errors.duty_stamp_threshold?.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center" marginTop="8px">
            <Grid item xs={12} sm={4}>
              <FormLabel>Copy Invoice Fee</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                {...register('copy_invoice_fee', {
                  required: 'Copy Invoice Fee is required',
                  valueAsNumber: true,
                })}
                error={Boolean(errors.copy_invoice_fee)}
                helperText={errors.copy_invoice_fee?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(setOpen)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => handleCreateEdit(formType)}
            disabled={loadingCreate || loadingEdit}
          >
            {loadingCreate || loadingEdit ? 'Loading...' : 'Save'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
