const INITIAL_STATE = {
  dataUserAdminAuth: {
    access_token: '',
    meta_access_token: {
      exp: 0,
      iat: 0,
      iss: '',
      nbf: 0,
      sub: '0',
    },
  },
  dataUserAdminProfile: { id: 0, email: '' },
};

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_USER_ADMIN_AUTH':
      return {
        ...state,
        dataUserAdminAuth: action.data,
      };
    case 'DATA_USER_ADMIN_AUTH_EMPTY':
      return {
        ...state,
        dataUserAdminAuth: INITIAL_STATE,
      };

    case 'DATA_USER_ADMIN_PROFILE':
      return {
        ...state,
        dataUserAdminProfile: action.data,
      };
    default:
      return state;
  }
};

export default users;
