import { useState } from 'react';
import { useSelector } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

export default function useRequestOTP() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showOtp, setShowOtp] = useState(false);

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
  }));

  const postRequestOTP = () => {
    const getAuthHeader = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    };

    const data = {
      via: 'email',
      template_name: 'sms_otp',
    };

    setShowOtp(true);

    api
      .post(endpoint.POST_ADMIN_REQUEST_OTP, data, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setShowOtp(true);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        console.log(err);
      });
  };

  return {
    phoneNumber,
    setPhoneNumber,
    postRequestOTP,
    showOtp,
    setShowOtp,
  };
}
