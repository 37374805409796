import {
  CardContent,
  CardActions,
  Card,
  Container,
  Stack,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { usePaymentFee } from './hooks';

export default function PaymentFeeB2BPage() {
  const {
    dataPaymentFee,
    doEditPaymentFee,
    openModalForm,
    setOpenModalForm,
    feeName,
    setFeeName,
    feeType,
    setFeeType,
    feeAmount,
    setFeeAmount,
    viewDataPayment,
    loading,
  } = usePaymentFee();

  return (
    <>
      <Helmet>
        <title> Payment Fee B2B | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Payment Fee B2B
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          {dataPaymentFee.map((value, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <Box sx={{ maxWidth: 275 }} key={value}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Name : {value.name}
                    </Typography>
                    <Typography variant="h5" component="div">
                      Amount : {value.amount}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Type : {value.type}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" variant="outlined" onClick={() => viewDataPayment(value)}>
                      Update Fee
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Dialog open={openModalForm} onClose={() => setOpenModalForm(false)}>
          <DialogTitle>Update Fee</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mb: 2, mt: 1 }}
              label="Fee Name"
              variant="outlined"
              style={{ width: '100%' }}
              defaultValue={feeName}
              onChange={(e) => setFeeName(e.target.value)}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Fee Type ({feeType === 'nominal' ? 'Rp' : '%'})</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={feeType}
                label="Fee Type"
                onChange={(e) => setFeeType(e.target.value)}
              >
                <MenuItem value={'percentage'}>Percentage</MenuItem>
                <MenuItem value={'nominal'}>Nominal</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ mb: 2 }}
              label="Amount"
              variant="outlined"
              style={{ width: '100%' }}
              defaultValue={feeAmount}
              onChange={(e) => setFeeAmount(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" onClick={doEditPaymentFee}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
