import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';
import { paramsToQuery } from '../../utils/formatParamsToQuery';
import { saveAs } from '../../utils/saveAs';

// get bills
export const useGetBills = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async () => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.BILLS}/admin?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getBills', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// download bills
export const useDownloadBills = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const download = async (id) => {
    setIsloading(true);
    try {
      const response = await axios.get(`${endpoint.BILLS}/download/${id}`, {
        responseType: 'arraybuffer', // Important for binary data
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      saveAs(response.data, `bills-${id}.pdf`);
    } catch (err) {
      toastAlert(err.response.statusText, 'error');

      if (err.response.data.error_code === 'E-00050') {
        setTimeout(() => {
          navigate('/login');

          dispatch({
            type: 'DATA_USER_ADMIN_AUTH_EMPTY',
          });
        }, 500);
      }
    } finally {
      setIsloading(false);
    }

    return null;
  };

  return { download, isLoading };
};
