import { useEffect, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../../api/api';
import endpoint from '../../../../constant/endpoint';
import toastAlert from '../../../../constant/toast';
import { paramsToQuery } from '../../../../utils/formatParamsToQuery';

// get payout req company
export const useGetPayoutReqCompany = (params) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGet = async (params) => {
    try {
      const query = paramsToQuery(params);

      const res = await api.get(`${endpoint.PAYOUT}/admin/req/company?${query}`, getAuthHeader);

      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useQuery({
    queryKey: ['getPayoutReqCompany', { ...params }],
    queryFn: () => doGet({ ...params }),
    keepPreviousData: true,
    staleTime: 0,
  });
};

// post confirm req company
export const useConfirmPayoutReq = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doAction = async (payload) => {
    try {
      const res = await api.post(
        `${endpoint.PAYOUT}/admin/${payload.id}/confirm-reject`,
        {
          note: payload.note,
          status: payload.status,
          otp: payload.otp,
        },
        getAuthHeader
      );

      if (res.data.success) {
        toastAlert('Berhasil, mengubah status', 'success');

        payload.setShowOtp(false);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return useMutation(doAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getPayoutReqCompany']);
    },
  });
};

// get detail req company
export const useGetDetailPayoutReqCompany = () => {
  const [dataDetailReq, setDataDetailReq] = useState({
    updated_at: '',
    company: { name: '' },
    payment_method: '',
    transaction_id: '',
    status: '',
    amount: '',
    fee: '',
    total: '',
    bank_currency: '',
    name: '',
    account_number: '',
    account_name: '',
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const params = useParams();

  useEffect(() => {
    doGetDetail(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doGetDetail = async (id = '') => {
    try {
      const res = await api.get(`${endpoint.PAYOUT}/admin/${id}`, getAuthHeader);

      if (res.data.success) {
        setDataDetailReq(res.data.data);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const refetch = async () => {
    await doGetDetail(params.id);
  };

  return {
    dataReqDetail: dataDetailReq,
    refetch,
  };
};
