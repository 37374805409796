import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';

import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import Datatable from '../../../../components/datatable';

export default function PayoutVendorMethodsPage() {
  const cellStyle = { fontSize: 14 };

  const navigate = useNavigate();

  // state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  const datas = [
    {
      name: 'Bank Transfer',
      status: 'Active',
    },
    {
      name: 'Binance',
      status: 'Active',
    },
  ];

  const columns = [
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          No
        </Typography>
      ),
      width: '4rem',
      cell: (_, i) => <Typography sx={{ ...cellStyle }}>{i + 1}.</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Name
        </Typography>
      ),
      width: '12rem',
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle }}>{row.name}</Typography>,
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Status
        </Typography>
      ),
      sortable: true,
      cell: (row) => {
        let buttonColor = 'primary';
        if (row.status === 'Active') {
          buttonColor = 'success';
        } else if (row.status === 'InActive') {
          buttonColor = 'error';
        }

        return (
          <Label
            variant="filled"
            color={buttonColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              color: '#fff',
            }}
          >
            {row.status}
          </Label>
        );
      },
    },
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          Action
        </Typography>
      ),
      sortable: true,
      cell: (row) => (
        <Label
          variant="filled"
          color={'primary'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/payputs/vendor/log/view?payout_id=${row.id}`);
          }}
        >
          <Iconify style={{ color: '#fff' }} icon={'eva:eye-fill'} /> &nbsp; View
        </Label>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Vendor Payout Method | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vendor Payout Method
          </Typography>
        </Stack>

        <Card>
          <Box paddingY={2} paddingX={3} display="flex" justifyContent="end">
            <Button
              variant="contained"
              size="large"
              startIcon={<Iconify icon="eva:plus-fill" sx={{ color: 'white', width: 20, height: 20 }} />}
            >
              Add
            </Button>
          </Box>
          <Datatable
            columns={columns}
            data={datas}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        </Card>
      </Container>
    </>
  );
}
