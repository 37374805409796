import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';
// @mui
// sections
import { List } from './sections';
// hooks
import useTicket from './hooks/useTicket';

// ----------------------------------------------------------------------

export default function TicketListPage() {
  const { dataTicket, getDataTicket, dataTotalTable } = useTicket();

  return (
    <>
      <Helmet>
        <title> Support Ticket | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Support Ticket
          </Typography>
        </Stack>

        <Card>
          <List dataTicket={dataTicket} dataTotalTable={dataTotalTable} getDataTicket={getDataTicket} />
        </Card>
      </Container>
    </>
  );
}
