import Axios from 'axios';

const api = Axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
  data: {},
  timeout: 15000,
});

export default api;
