import { useState } from 'react';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useChangePassword = (email) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepetition, setNewPasswordRepetition] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [button, setButton] = useState('SUBMIT');

  const postForgotPassword = async () => {
    setButton('Loading...');

    const data = {
      email,
      new_password: newPassword,
      new_password_repetition: newPasswordRepetition,
      old_password: oldPassword,
    };

    api
      .post(endpoint.POST_ADMIN_CHANGE_PASSWORD, data)
      .then((res) => {
        setButton('Loading...');

        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          setButton('SUBMIT');
          setNewPassword('');
          setNewPasswordRepetition('');
          setOldPassword('');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('SUBMIT');
        console.log(err);
      });
  };

  return {
    newPassword,
    setNewPassword,
    newPasswordRepetition,
    setNewPasswordRepetition,
    oldPassword,
    setOldPassword,
    postForgotPassword,
    button,
  };
};

export default useChangePassword;
