import { Card, Container, Stack, Typography, Button, IconButton, Popover, MenuItem } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from '../../../components/iconify';

import FormCreateRules from './form';
import Datatable from '../../../components/datatable';
import { useIndexPaylaterRules } from './hooks';
import DeleteDialog from '../../../components/dialog/delete-dialog';

export default function PaylaterRulesPage() {
  const cellStyle = { fontSize: 14 };

  const {
    data,
    isLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    showCreate,
    setShowCreate,
    showEdit,
    setShowEdit,
    showDelete,
    setShowDelete,
    open,
    setOpen,
    idToAction,
    setIdToAction,
    handleDelete,
    loadingDelete,
  } = useIndexPaylaterRules();

  const columns = [
    {
      name: (
        <Typography variant="subtitle2" noWrap>
          No
        </Typography>
      ),
      width: '4rem',
      cell: (_, i) => <Typography sx={{ ...cellStyle }}>{i + 1}.</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Loan Type</Typography>,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.loan_type}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Loan Period</Typography>,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.loan_period}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Fee</Typography>,
      sortable: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.loan_fee_rate}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Late Fee</Typography>,
      sortable: true,
      cell: (row) => (
        <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.loan_late_fee_rate}</Typography>
      ),
    },
    {
      name: <Typography variant="subtitle2">Accelerated Repayment Fee</Typography>,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.accelerated_repayment_fee}</Typography>
      ),
    },
    {
      name: <Typography variant="subtitle2">Duty Stamp Fee</Typography>,
      sortable: true,
      wrap: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.duty_stamp_fee}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Duty Stamp Threshold</Typography>,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.duty_stamp_threshold}</Typography>
      ),
    },
    {
      name: <Typography variant="subtitle2">Copy Invoice Fee</Typography>,
      sortable: true,
      wrap: true,
      cell: (row) => <Typography sx={{ ...cellStyle, textTransform: 'uppercase' }}>{row.copy_invoice_fee}</Typography>,
    },
    {
      name: <Typography variant="subtitle2">Action</Typography>,
      sortable: true,
      cell: (row) => (
        <IconButton
          size="large"
          color="inherit"
          onClick={(event) => {
            setIdToAction(row.id);
            setOpen(event.currentTarget);
          }}
        >
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Paylater Rules | SAAS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Paylater Rules
          </Typography>
          <Button size="small" variant="contained" onClick={() => setShowCreate(true)}>
            Add Paylater Rules
          </Button>
        </Stack>

        {/* table */}
        <Card>
          <Datatable
            columns={columns}
            data={data?.data?.data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            progressPending={isLoading}
            totalData={data?.data.total_data ?? 0}
          />
        </Card>

        {/* menu action */}
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => setOpen(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={() => setShowEdit(true)}>Edit</MenuItem>
          <MenuItem onClick={() => setShowDelete(true)}>Delete</MenuItem>
        </Popover>

        {/* form create */}
        <FormCreateRules open={showCreate} setOpen={setShowCreate} formType="Create" />

        {/* form edit */}
        <FormCreateRules open={showEdit} setOpen={setShowEdit} formType="Edit" id={idToAction} />

        {/* delete dialog */}
        <DeleteDialog
          open={showDelete}
          setOpen={setShowDelete}
          alertTitle="Delete Paylater Rule"
          handleDelete={handleDelete}
          loadingDelete={loadingDelete}
        />
      </Container>
    </>
  );
}
