import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Grid, Typography, Alert, CardContent, Container, Stack, Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { formatIDR } from '../../utils/formatNumber';


import { useDashboardB2B } from './hooks';

export default function DashboardB2B() {
  const { dataDashboardMarketplace, dataDashboardMerchant } = useDashboardB2B();
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
          Dashboard | B2B
        </Typography>
      </Stack>
      <Grid container spacing={3}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Marketplace" value="1" />
                <Tab label="Merchant" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={3}>
                {dataDashboardMarketplace.map((value, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ maxWidth: 350 }}>
                      <CardHeader title={value?.name} subheader={value?.email} />
                      <CardContent>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Paid Gateway Count: {value?.invoice_paid_gateway_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Paid Manual Count: {value?.invoice_paid_manual_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Pending Count: {value?.invoice_pending_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Settled Count: {value?.invoice_settled_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice UnSettled Count: {value?.invoice_unsettled_count}
                        </Typography>

                        <hr />

                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Paid Gateway Count: {value?.total_invoice_paid_gateway ? formatIDR(value?.total_invoice_paid_gateway) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Paid Manual Count: {value?.total_invoice_paid_manual ? formatIDR(value?.total_invoice_paid_manual) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Pending Count: {value?.total_invoice_pending ? formatIDR(value?.total_invoice_pending) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Settled Count:  {value?.total_invoice_settled ? formatIDR(value?.total_invoice_settled) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice UnSettled Count: {value?.total_invoice_unsettled ? formatIDR(value?.total_invoice_unsettled) : 0}
                        </Typography>

                        <Alert severity="success" sx={{marginTop: 2}}>
                          <b>Balance: Rp. {value?.balance ? formatIDR(value?.balance) : 0}</b>
                        </Alert>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {dataDashboardMarketplace.length === 0 && (
                  <p>Data is missing...</p>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={3}>
                {dataDashboardMerchant.map((value, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ maxWidth: 350 }}>
                      <CardHeader title={value?.name} subheader={value?.email} />
                      <CardContent>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Paid Gateway Count: {value?.invoice_paid_gateway_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Paid Manual Count: {value?.invoice_paid_manual_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Pending Count: {value?.invoice_pending_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice Settled Count: {value?.invoice_settled_count}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Invoice UnSettled Count: {value?.invoice_unsettled_count}
                        </Typography>

                        <hr />

                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Paid Gateway Count: {value?.total_invoice_paid_gateway ? formatIDR(value?.total_invoice_paid_gateway) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Paid Manual Count: {value?.total_invoice_paid_manual ? formatIDR(value?.total_invoice_paid_manual) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Pending Count: {value?.total_invoice_pending ? formatIDR(value?.total_invoice_pending) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice Settled Count:  {value?.total_invoice_settled ? formatIDR(value?.total_invoice_settled) : 0}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          Total Invoice UnSettled Count: {value?.total_invoice_unsettled ? formatIDR(value?.total_invoice_unsettled) : 0}
                        </Typography>

                        <Alert severity="success" sx={{marginTop: 2}}>
                          <b>Balance: Rp. {value?.balance ? formatIDR(value?.balance) : 0}</b>
                        </Alert>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {dataDashboardMerchant.length === 0 && (
                  <p>Data is missing..</p>
                )}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Container>
  );
}
