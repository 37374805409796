const navConfig = [
  {
    title: 'dashboard B2B',
    path: '/dashboard/b2b',
    icon: 'carbon:dashboard',
  },
  {
    title: 'dashboard B2C',
    path: '/dashboard/app',
    icon: 'carbon:dashboard',
  },
  {
    title: 'Agregator Fee',
    path: '/agregator-fee/list',
    icon: 'streamline:payment-10',
  },
  {
    title: 'Payment Fee',
    path: '/payment-fee/list',
    icon: 'streamline:payment-10',
  },
  {
    title: 'Payment Fee B2B',
    path: '/payment-fee-b2b/list',
    icon: 'streamline:payment-10',
  },
  {
    title: 'Bills List',
    path: '/bills/list',
    icon: 'material-symbols:monetization-on-outline-rounded',
  },
  {
    title: 'Company List',
    path: '/company/list',
    icon: 'mdi:company',
  },
  {
    title: 'Contact List',
    path: '/contact/list',
    icon: 'material-symbols:contacts-outline',
  },
  {
    title: 'Paylater Rules',
    path: '/paylater/rules',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Paylater Tiers',
    path: '/paylater/tiers',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Payout B2B Log',
    path: '/gateway/log',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Payout Request User B2B',
    path: '/gateway/list',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Entri Manual Withdraw ',
    path: '/gateway/add',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Invoice List',
    path: '/invoices/list',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Settlement B2B',
    path: '/settlement-b2b/list',
    icon: 'material-symbols:sticky-note-2-outline',
  },
  {
    title: 'Services List',
    path: '/services/list',
    icon: 'arcticons:services',
  },
  {
    title: 'Support Ticket',
    path: '/ticket/list',
    icon: 'majesticons:tickets',
  },
  {
    title: 'User B2B',
    path: '/user-b2b/list',
    icon: 'mdi:user-box',
  },
  {
    title: 'Payout B2C Log',
    path: '/payouts/setting/request/list',
    icon: 'charm:git-request',
  },
  {
    title: 'Payout Log Setting',
    path: '/payouts/setting/log/list',
    icon: 'pajamas:log',
  },
  {
    title: 'Payout Request User B2C',
    path: '/payouts/vendor/request/list',
    icon: 'fluent-mdl2:file-request',
  },
  {
    title: 'Payout Log Vendor',
    path: '/payouts/vendor/log/list',
    icon: 'icon-park-twotone:log',
  },
];

export default navConfig;
