/**
 * Converts an object of parameters into a query string.
 *
 * @param {Object} params - The parameters to be converted.
 * @returns {string} The generated query string.
 * @example
 * const params = {
 *   page: 1,
 *   page_size: 10,
 * };
 * const query = paramsToQuery(params);
 * // Result: "page=1&page_size=10"
 */

export function paramsToQuery(params) {
  let query = '';

  Object.keys(params).forEach((key, index) => {
    if (index > 0) {
      query += '&'; // Add '&' between key-value pairs if not the first pair
    }
    query += `${key}=${params[key]}`;
  });

  return query;
}
