import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

export const useDashboardB2B = () => {
  const navigate = useNavigate();
  const [dataDashboardMarketplace, setDataDashboardMarketplace] = useState([]);
  const [dataDashboardMerchant, setDataDashboardMerchant] = useState([]);

  useEffect(() => {
    doGetDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGetDashboard = async () => {
    try {
      const res = await api.get(`${endpoint.DASHBOARD_B2B}`, getAuthHeader);

      if (res.data.success) {
        console.log('res', res.data);
        setDataDashboardMarketplace(res.data.data.marketplace);
        setDataDashboardMerchant(res.data.data.merchant);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  return {
    dataDashboardMarketplace,
    dataDashboardMerchant
  };
};
