import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

export const usePaymentFee = () => {
  const navigate = useNavigate();
  const [dataPaymentFee, setDataPaymentFee] = useState([]);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [feeEmail, setFeeEmail] = useState('');
  const [feeName, setFeeName] = useState('');
  const [feeType, setFeeType] = useState('');
  const [feeAmount, setFeeAmount] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    doGetPaymentFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const doGetPaymentFee = async () => {
    try {
      const res = await api.get(`${endpoint.GATEWAY_FEE}`, getAuthHeader);

      if (res.data.success) {
        setDataPaymentFee(res.data.data);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');

        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');

            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
    }

    return null;
  };

  const doEditPaymentFee = async () => {
    try {
      const dataForm = {
        fee_amount: parseFloat(feeAmount),
        name: feeName,
        fee_type: feeType,
      };

      setLoading(true);

      const res = await api.patch(`${endpoint.GATEWAY_FEE}`, dataForm, getAuthHeader);

      if (res.data.success) {
        toastAlert(res.data.message, 'success');

        setOpenModalForm(false);
        doGetPaymentFee();
        setLoading(false);
      }
    } catch (err) {
      if (!err.response.data.success) {
        toastAlert(err.response.data.message, 'error');
        if (err.response.data.error_code === 'E-00050') {
          setTimeout(() => {
            navigate('/login');
            dispatch({
              type: 'DATA_USER_ADMIN_AUTH_EMPTY',
            });
          }, 500);
        }
      }
      setLoading(false);
    }

    return null;
  };

  const viewDataPayment = (value) => {
    setFeeName(value.name);
    setFeeType(value.type);
    setFeeAmount(value.amount);

    setOpenModalForm(true);
  };

  return {
    dataPaymentFee,
    doEditPaymentFee,
    openModalForm,
    setOpenModalForm,
    feeEmail,
    setFeeEmail,
    feeName,
    setFeeName,
    feeType,
    feeAmount,
    setFeeAmount,
    viewDataPayment,
    setFeeType,
    loading,
  };
};
