import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useLogin = () => {
  const [username, setUsername] = useState('');
  const [passwordData, setPasswordData] = useState('');
  const [button, setButton] = useState('Login');

  const navigate = useNavigate();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
  }));

  if (user.access_token) {
    navigate('/payment-fee/list');
  }

  const postLogin = async () => {
    setButton('Loading...');

    const data = {
      email: username,
      password: passwordData,
    };

    api
      .post(endpoint.POST_ADMIN_LOGIN, data)
      .then((res) => {
        if (res.data.success) {
          navigate(`/otp?email=${username}`);
          setButton('Login');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }
        setButton('Login');
        console.log(err);
      });
  };

  return {
    username,
    setUsername,
    passwordData,
    setPasswordData,
    postLogin,
    button,
  };
};

export default useLogin;
