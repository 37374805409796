import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button } from '@mui/material';

import { faker } from '@faker-js/faker';

// sections
import { AddFormView, Comment } from './sections';

// components
import Iconify from '../../components/iconify';
import useTicket from './hooks/useTicket';

// ----------------------------------------------------------------------

export default function TicketViewPage() {
  const {
    handleChangeTicketReply,
    dataTicketDetail,
    buttonReply,
    postDataTicketReply,
    dataFormTicketReply,
    postDataTicketClose,
    buttonClose,
    postDataFileImage,
    loadingImage,
  } = useTicket();

  const title = `Ticket#[${dataTicketDetail.id}] ${
    dataTicketDetail.ticket_details.length > 0 ? dataTicketDetail.ticket_details[0].chat : ''
  } !`;

  return (
    <>
      <Helmet>
        <title> Support Ticket: View | SASS Invoice </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            <Iconify icon="eva:plus-fill" /> View Tickets
          </Typography>
          <Link to="/ticket/list" component={RouterLink} sx={{ display: 'contents' }}>
            <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />}>Back to list</Button>
          </Link>
        </Stack>

        <Grid item xs={12} md={6} lg={8}>
          <AddFormView
            handleChangeTicketReply={handleChangeTicketReply}
            dataTicketDetail={dataTicketDetail}
            status={dataTicketDetail.status}
            title={title}
            buttonReply={buttonReply}
            postDataTicketReply={postDataTicketReply}
            dataFormTicketReply={dataFormTicketReply}
            postDataTicketClose={postDataTicketClose}
            buttonClose={buttonClose}
            postDataFileImage={postDataFileImage}
            loadingImage={loadingImage}
          />
        </Grid>
        <br />
        <Grid item xs={12} md={6} lg={8}>
          <Comment
            title="News Update"
            dataTicketDetail={dataTicketDetail}
            list={[...Array(5)].map((_, index) => ({
              id: faker.datatype.uuid(),
              title: faker.name.jobTitle(),
              description: faker.name.jobTitle(),
              image: `/assets/images/covers/cover_${index + 1}.jpg`,
              postedAt: faker.date.recent(),
            }))}
          />
        </Grid>
      </Container>
    </>
  );
}
